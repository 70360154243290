$yellow: #718792;
$blue: #009fe370;
$blue-original: #009fe3;
$blue-min: #009fe360;
$light-blue: #62d0ff;
$white-blue: #B1BCFF;
$blue-white: #F3F5FF;
$pink: #702283;
//$dark-pink: #ff0f60;
$dark-pink: #410056;
$green: #3CD4A0;
$violet: #9013FE80;
$white: white;
$dark-grey: #4A4A4A;
$light-grey: #B9B9B9;
$grey: #6E6E6E;
$sky: #c0caff;
$white-35: rgba(255, 255, 255, 0.35);
$white-80: #FFFFFF80;
$gray-08: rgba(110, 110, 110, 0.8);
$gray-80: #D8D8D880;
$gray-06: rgba(110, 110, 110, 0.6);
$black-08: rgba(0, 0, 0, 0.08);
$pink-15: rgba(255, 92, 147, 0.15);
$blue-15: rgba(83, 109, 254, 0.15);
$green-15: rgba(60, 212, 160, 0.15);
$yellow-15: rgba(255, 194, 96, 0.15);
$violet-15: rgba(144, 19, 254, 0.15);
$shadow-white: #E8EAFC;
$shadow-grey: #B2B2B21A;
$shadow-dark-grey: #9A9A9A1A;
//$background-color: #F6F7FF;
$background-color: #706f6f;